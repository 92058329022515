<template>
  <div class="container">
    <div class="card overlapping-container has-top-shadow">
      <div class="card-content overflow-y pb-6 mb-3 px-4 pt-1">
        <CardNavigation/>
        <ViewsStat :pageDetails="{pageName: 'inventory'}"/>
        <div class="container has-text-centered mb-1">
          <div class="pt-4 is-size-5 has-text-weight-semibold has-baskerville-font has-text-brand-color">
            {{ $store.state.uiMods.inventory }}
          </div>
        </div>
        <!-- <div class="container has-text-centered mb-3">
          <Inventory1/>
        </div> -->
        <div class="container is-flex is-flex-direction-column">
          <div class="is-size-6 has-text-grey px-4">
            {{ $store.state.uiMods.inventorySubtext ? $store.state.uiMods.inventorySubtext : 'An "item" can be a mix of texts, photos or URL links.'}}
          </div>
          <div v-if="categories && categories.length > 1" class="container horizontal-scroll mb-0 mt-2">
            <div
            v-for="(category, index) in categories"
            :key="index + categoryFilter"
            class="notranslate button is-small is-rounded mr-1"
            :class="{'is-primary': !categoryFilter && category === 'Show All' || categoryFilter && category === categoryFilter}"
            @click="handleCategoryClick(category)"
            >
            {{ category }}
            </div>
          </div>
          <div v-if="items.length === 0" class="is-size-6 has-text-grey mb-5 px-4 has-text-centered mt-6 pt-6">
            <i class="fas fa-archive mr-1"></i>
            Nothing in your {{ $store.state.uiMods.inventory.toLowerCase() }} for now.
          </div>
          <div class="mb-3"></div>
          <div
            v-for="item in shownItems"
            :key="item.item"
            class="card py-4 px-5 mb-2"
            style="width: 100%;"
          >
            <div v-if="item.stage" class="py-1 is-size-7 has-baskerville-font">
              From Chapter {{ item.stage }}: {{ item.challengeName }}
            </div>
            <div class="notranslate" v-markdown>{{ item.item }}</div>
          </div>
          <div class="container has-text-centered mb-5 pb-5">
            <div @click="$router.push({ name: 'Stage' })" class="button is-small is-primary is-rounded mt-3">
              <i class="fas fa-clipboard mr-2"></i>
              Back to {{ $store.state.uiMods.task.toLowerCase() }}s page
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardNavigation from '@/components/CardNavigation'
// import Inventory1 from '@/components/svgs/Inventory1'
import ViewsStat from '@/components/ViewsStat.vue'

export default {
  name: 'Inventory',
  components: {
    // Inventory1,
    ViewsStat,
    CardNavigation
  },
  data () {
    return {
      categoryFilter: 'Show All'
    }
  },
  computed: {
    adventureTeam() {
      return this.$store.state.adventureTeam
    },
    items(){
      if(this.adventureTeam){
        return this.adventureTeam.items
      } else {
        return null
      }
    },
    shownItems(){
      const sortedItems = this.items.slice()
      if (this.adventureTeam.uiMods.itemsSortedFirstAtTop) {
        sortedItems.reverse()
      }
      sortedItems.sort((a, b) => {
        if (a.order === null || a.order === undefined) return -1
        if (b.order === null || b.order === undefined) return 1
        return a.order - b.order
      })
      if(this.categoryFilter === 'Show All') return sortedItems
      return sortedItems.filter(item => item.category && item.category === this.categoryFilter)
    },
    categories(){
      if (!this.items) return []
      return ['Show All'].concat(
        [...new Set(this.items.filter(x => x.category).map(x => x.category))])
    }
  },
  methods: {
    showPhotoViewer(event){
      this.$store.commit('showPhotoViewer', event.target.dataset.url)
    },
    onScroll(e) {
      this.$store.commit('updateScrollPositions', {
        pageName: this.$route.name,
        scrollPosition: window.top.scrollY
      })
    },
    handleCategoryClick(category) {
      this.categoryFilter = category
      localStorage.monabrunItemCategoryFilter = category
    }
  },
  updated() {
    document.querySelectorAll('img').forEach(item => {
      item.removeEventListener('click', this.showPhotoViewer)
      item.addEventListener('click', this.showPhotoViewer)
    })
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll)

    this.$store.commit('updateShowInventoryAlert', false)

    document.querySelectorAll('img').forEach(item => {
      item.removeEventListener('click', this.showPhotoViewer)
      item.addEventListener('click', this.showPhotoViewer)
    })

    this.$store.dispatch('enterPage', {
      pageName: 'inventory'
    })

    if(
      localStorage.monabrunItemCategoryFilter &&
      this.categories.includes(localStorage.monabrunItemCategoryFilter)
    ){
      this.categoryFilter = localStorage.monabrunItemCategoryFilter
    }
  },
  beforeDestroy() {
    this.$store.commit('updateShowInventoryAlert', false)
    window.removeEventListener('scroll', this.onScroll)
  }
}
</script>
